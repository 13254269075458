import {DOMAIN_API} from "./local_settings";
import {
    DOMAIN_DEMO_NAME, DOMAIN_DEMO_API
} from "./demo_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {SettingsRequestsModel} from "../models/settings/settings_requests.model";
import {environment} from "../../environments/environment";
import {PartnersRequestsModel} from "../models/partners/partners_requests.model";

let API = DOMAIN_API + '/ads';
if(window.location.hostname == DOMAIN_DEMO_NAME){
    API = DOMAIN_DEMO_API + '/ads';
}

if (environment.frontEndTest) {
    API = DOMAIN_API + '/pos';
}

export const AUTH_CONFIG: AuthConfig = {
    checkPhoneAndSendPin: API + '/senetpro/register/create',
    login: API + '/senetpro/register/verify',
};
if (environment.frontEndTest) {
    AUTH_CONFIG.checkPhoneAndSendPin = API + '/jsons/find/file?name=success';
    AUTH_CONFIG.login = API + '/jsons/find/file?name=pnSweepsSenetLogin';
}

export const SETTINGS_CONFIG: SettingsRequestsModel = {
    registerCashapp: API + '/senetpro/register/cashapp',
};
if (environment.frontEndTest) {
    SETTINGS_CONFIG.registerCashapp = API + '/jsons/find/file?name=success';
}

export const PARTNERS_CONFIG: PartnersRequestsModel = {
    partner: API + '/senetpro/register/partner'
};
if (environment.frontEndTest) {
    PARTNERS_CONFIG.partner = API + '/jsons/find/file?name=partnerPnSweeps';
}

export const CASH_APP_CONFIG = {
    getSettings: API + '/senetpro/register/settings',
    payment: API + '/senetpro/register/payment'
};
if (environment.frontEndTest) {
    CASH_APP_CONFIG.getSettings = API + '/jsons/find/file?name=getCashAppSettings';
    CASH_APP_CONFIG.payment = API + '/jsons/find/file?name=success';
}
