import {Component, OnInit} from '@angular/core';
import {SessionStorageService} from "../../service/session/sessionStorage.service";
import {CashappPayService} from "../../service/cashapp-pay.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {Router} from "@angular/router";
import {LoaderService} from "../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-registered',
    templateUrl: './registered.component.html',
    styleUrls: ['./registered.component.scss']
})
export class RegisteredComponent implements OnInit {
    cashAppForm: FormGroup;

    cashAppLink = '';
    appLink = '';

    showCashAppForm = false;
    constructor(private sessionStorageService: SessionStorageService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private formBuilder: FormBuilder,
                private cashappPayService: CashappPayService) {}

    async ngOnInit() {
        this.cashAppForm = this.formBuilder.group({
          amount: [null, Validators.required],
        });

        this.getCashAppSettings();

        this.cashappPayService.destroyCashApp();

        this.cashAppLink = this.sessionStorageService.getCashappLink();
        this.appLink = this.sessionStorageService.getAppLink();

        this.cashappPayService.paymentResult.subscribe(tokenResult => {
            //console.log(tokenResult);
            if (tokenResult) {
                try {
                    // console.log(tokenResult, '1');
                    if (tokenResult.status === 'OK') {
                        // console.log(`Payment token is ${tokenResult.token}`);

                        const body = {
                            token: tokenResult.token
                        };
                        // $.post("https://api.dev.eboxenterprises.com/test/square.php?tt=1", body, (data, status) => {
                        //   console.log(data);
                        // });
                        this.onDepositConfirm(tokenResult.token);
                    } else {
                        // console.log(tokenResult, '2');
                        this.popupComponent.showPopupError({text: 'Payment is canceled'});
                        this.cashAppForm.controls.amount.reset();
                        this.closeCashAppForm();
                    }
                    this.cashappPayService.paymentResult.next(null);
                } catch (e) {
                    console.log(e);
                }

            }
        })
    }

    getCashAppSettings() {
      // const data = {
      //   status: true,
      //   cashapp: {
      //     "appId": "sandbox-sq0idb-vroAoylGDSnNU--Au1YG2w",
      //     "locationId": "LFHR510D04M1J",
      //     "mode": "sandbox"
      //   },
      //   message: 'OK'
      // };

      this.cashappPayService.getSettings().subscribe(data => {
        this.setCashAppSettings(data);
      }, () => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    }

    async setCashAppSettings(data) {
      try {
        if (data.status) {
          if (!this.cashappPayService.isCashAppPayScripExist()) {
              const amount = '1.00';
              await this.cashappPayService.createCashAppPayButton(data['cashapp'], amount);
          }
        } else {
          this.popupComponent.showPopupError({text: data.message});
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
    }

    getAppLink() {
        return this.appLink + '/senetpro?token=' + this.sessionStorageService.getUserToken();
    }

    getUserToken(){
        return this.sessionStorageService.getUserToken();
    }

    onDepositConfirm(token) {
        const amount = this.cashAppForm.controls.amount.value;
        const phone = this.sessionStorageService.getPhoneNumber();

        this.cashappPayService.onConfirmPayment(amount, token, phone).subscribe(data => {
        // const data = {
        //     status: true,
        //     message: `All is OK buyWithCashApp, ${amount}, ${token}`
        // };
          this.setDepositConfirmResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setDepositConfirmResult(data) {
        try {
            if (data.status) {
                // this.popupComponent.showPopupErrorReturnEvent({text: data.message}, () => {
                //     window.location.href = this.getAppLink();
                // });
                this.closeCashAppForm();
                this.popupComponent.showPopupSuccess({text: data.message});
                window.location.href = this.getAppLink();
            } else {
                const amount = +this.cashAppForm.controls.amount.value;
                this.cashappPayService.changeAmount(amount.toFixed(2));
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            const amount = +this.cashAppForm.controls.amount.value;
            this.cashappPayService.changeAmount(amount.toFixed(2));
            this.loaderService.hideAndShowTryAgain();
        }
    }

    async openCashAppForm() {
      this.showCashAppForm = true;
      document.body.querySelector('.popup-cash-app').classList.add('is-active');
      document.body.classList.add('is-blurred');
      await this.cashappPayService.showCashAppPayButton('1.00');
    }

    closeCashAppForm() {
      this.showCashAppForm = false;
      document.body.querySelector('.popup-cash-app').classList.remove('is-active');
      document.body.classList.remove('is-blurred');
      this.cashAppForm.reset();
    }

    openCashAppFormConfirm() {
      this.cashAppForm.controls.amount.disable();
      setTimeout(async () => {
        await this.cashappPayService.showCashAppPayButton('0.00');
      })
    }

    onAmountChanged(e) {
      let amount = +e.target.value.replace(/\s/g, '');
      if (!amount) {
        amount = 1;
      }
      this.cashappPayService.changeAmount(amount.toFixed(2));
    }
}
