import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {PartnersService} from "../../service/partners/partners.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class HomeComponent implements OnInit {
    // partnerLogo = '../assets/images/logo.png';
    partnerLogo = '';

    constructor(private partnersService: PartnersService) {
    }


    ngOnInit() {
        this.partnerLogo = this.partnersService.getLogo();
        this.partnersService.updatePartnerInfo();
    }
}
