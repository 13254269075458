<div class="login__forms">
    <div class="login__tab">
        <form [formGroup]="userForm" (ngSubmit)="isPinShown ? onUserFormSubmit() : onPhoneSubmit()" class="login__form">
            <div class="login__text">
                <p class="text" style="text-align: center">
                    Enter your phone number. It will be used to security log you in.
                </p>
            </div>
            <div class="login__item login__item--mb">
                <input [mask]="phoneMask"
                       formControlName="phone"
                       placeholder="Phone Number"
                       [type]="'tel'"
                       class="input input--uppercase"
                       autocomplete="off">
            </div>

            <div class="login__btns" *ngIf="!isPinShown">
                <button class="button" [disabled]="!userForm.controls.phone.valid">
                    Next
                </button>
            </div>

            <div class="login__pin"  *ngIf="isPinShown">
                <p>Enter the four-digit pin sent to your number</p>
                <div class="login__items" formArrayName="digits">
                    <input (keydown.enter)="onUserFormSubmit()" *ngFor="let field of userForm.get('digits')['controls']; let i = index" #inputs [maxLength]="1" [formControlName]="i"  (keydown)="check(i, field, $event)"
                           mask="0"
                           class="login-input-small" type="tel">
                </div>

                <button (click)="onResend()" class="button button--no-border" type="button">Resend</button>

                <div class="text-center form-btn-submit">
                    <button type="submit" class="button" [disabled]="!userForm.valid">
                        Next
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
