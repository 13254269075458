import { OnInit } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/popup/popup.component";
import * as i2 from "../../service/loader.service";
import * as i3 from "../../service/partners/partners.service";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(popupComponent, loaderService, partnersService) {
        this.popupComponent = popupComponent;
        this.loaderService = loaderService;
        this.partnersService = partnersService;
        // partnerLogo = '../assets/images/logo.png';
        this.partnerLogo = '';
        // this.activatedRoute.queryParams.subscribe(data => {
        //     if (data.notLogged) {
        //         this.logout();
        //         this.popupComponent.showPopupError({text: 'You are not logged in. Please log in and try again'});
        //     }
        // })
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.getPartnerInfo();
    };
    LoginComponent.prototype.getPartnerInfo = function () {
        var _this = this;
        this.partnersService.getPartner().subscribe(function (data) {
            try {
                if (data.status) {
                    _this.partnerLogo = data.logo;
                    _this.partnersService.setLogo(_this.partnerLogo);
                    _this.partnersService.setTitle(data.title);
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginComponent_Factory() { return new LoginComponent(i0.ɵɵinject(i1.PopupComponent), i0.ɵɵinject(i2.LoaderService), i0.ɵɵinject(i3.PartnersService)); }, token: LoginComponent, providedIn: "root" });
    return LoginComponent;
}());
export { LoginComponent };
