<div class="login__forms">
    <div class="login__tab">
        <div class="login__text">
            <p class="text" style="text-align: center">
                Great! You are now registered & ready to earn
                <span class="accent">10% commission </span>
                on each referral! Deposit money now using your
                Cash App to start earning and enter Sweepstakes
                for Golden Dragon, River, and much, much more.
            </p>
        </div>

        <div class="login__text">
            <p class="text" style="text-align: center">
                All deposits and redeems are automated. There is no need to contact any agent. Support lines in the app are open if you need help.
            </p>
        </div>
        <div class="login__btns">
            <button  class="button"
                     (click)="openCashAppForm()"
                     type="button">
                Deposit now
            </button>
            <a [href]="getAppLink()" *ngIf="getUserToken()" class="button"
               type="button">
                Explore our app
            </a>
        </div>
    </div>
</div>

<div class="popup-cash-app" [class.is-active]="showCashAppForm">
    <div class="popup-cash-app__inner">
        <button class="popup-cash-app__close" (click)="closeCashAppForm()"></button>
        <form [formGroup]="cashAppForm" class="login__form">
            <div class="login__item">
                <input formControlName="amount"
                       mask="separator.2"
                       placeholder="Amount"
                       (input)="onAmountChanged($event)"
                       [type]="'tel'"
                       class="input input--uppercase"
                       autocomplete="off">
            </div>

<!--            <button  class="button"-->
<!--                     *ngIf="!cashAppForm.controls.amount.disabled"-->
<!--                     (click)="openCashAppFormConfirm()"-->
<!--                     type="button">-->
<!--                Confirm-->
<!--            </button>-->

            <div id="payment-form" style="margin-top: 1.5rem" [class.is-disabled]="!+cashAppForm.controls.amount.value">
                <div id="cash-app-pay"></div>
            </div>
        </form>
    </div>
</div>
