import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from "./login/login.component";
import {AuthGuard} from '../modules/auth/_guards/auth.guard';
import {RegisteredComponent} from "./registered/registered.component";
import {PromoComponent} from "./promo/promo.component";
import {CashappComponent} from "./cashapp/cashapp.component";
import {PhoneComponent} from "./phone/phone.component";

const routes: Routes = [
    {
        path: '', component: MainComponent, children: [
            {path: '', component: LoginComponent, children: [
                    {path: '', component: PhoneComponent},
                    // {path: 'cashapp', component: CashappComponent},
                    // {path: 'phone', component: PhoneComponent},
                ]},
            {path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
            // {path: 'home', component: HomeComponent, children: [
                    {path: 'registered', component: RegisteredComponent},
                ]},
            {path: '**', redirectTo: '', pathMatch: 'full'}
        ]
    }

];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
