import {
    Component,
    OnInit, Injectable
} from '@angular/core';
import {slideToRight} from "../../animations/router.animations";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {PartnersService} from "../../service/partners/partners.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
@Injectable({providedIn: 'root'})
export class LoginComponent implements OnInit {
    // partnerLogo = '../assets/images/logo.png';
    partnerLogo = '';

    constructor(private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private partnersService: PartnersService) {
        // this.activatedRoute.queryParams.subscribe(data => {
        //     if (data.notLogged) {
        //         this.logout();
        //         this.popupComponent.showPopupError({text: 'You are not logged in. Please log in and try again'});
        //     }
        // })
    }
    ngOnInit() {
        this.getPartnerInfo();
    }

    getPartnerInfo() {
        this.partnersService.getPartner().subscribe(data => {
            try {
                if (data.status) {
                    this.partnerLogo = data.logo;
                    this.partnersService.setLogo(this.partnerLogo);
                    this.partnersService.setTitle(data.title);
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }
}

