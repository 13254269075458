import {
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChildren, Injectable
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {LoginService} from "../../service/login.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {PHONE_MASK} from "../../config/country_phone_mask";
import {RouterPaths} from "../../config/router-paths.model";
import {SettingsService} from "../../service/settings.service";
import {SessionStorageService} from "../../service/session/sessionStorage.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";

@Component({
    selector: 'app-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss'],
})
@Injectable({providedIn: 'root'})
export class PhoneComponent implements OnInit {
    userForm: FormGroup;

    phoneMask = PHONE_MASK.us;
    numOfDigits = 4;

    isPinShown = false;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    constructor(
      private authenticationService: AuthenticationService,
      private formBuilder: FormBuilder,
      private loginService: LoginService,
      private router: Router,
      private loaderService: LoaderService,
      private settingsService: SettingsService,
      private activatedRoute: ActivatedRoute,
      private sessionStorageService: SessionStorageService,
      private popupComponent: PopupComponent) {}

    ngOnInit() {
        this.authenticationService.logout();

        this.userForm = this.formBuilder.group({
            phone: [null, Validators.required],
            digits: this.formBuilder.array([])
        });
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.userForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    onPhoneSubmit(){
        if (this.userForm.controls.phone.invalid) {
            return;
        }

        const phone = this.userForm.controls.phone.value;

        this.authenticationService.checkPhoneAndSendPin(phone).subscribe(data => {
            try {
                if (data.status) {
                    this.showPinForm();
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    showPinForm() {
        this.isPinShown = true;
        setTimeout(() => {
            this.inputs.toArray()[0].nativeElement.focus();
        });
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    onUserFormSubmit(){
        if (this.userForm.invalid) {
            return;
        }

        // const promocode = this.sessionStorageService.getPromo();
        // const cashappTag = this.sessionStorageService.getCashAppTag();
        const phone = this.userForm.controls.phone.value;
        this.sessionStorageService.setPhoneNumber(phone);

        let pin = this.userForm.controls.digits.value;
        pin = pin.join('');

        if (pin.length !== 4) {
            this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'});
            return
        }

        if (phone && pin && (pin.length === 4)) {
            this.authenticationService.login(phone, pin).subscribe(data => {
                this.checkLoginSubmit(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    checkLoginSubmit(data) {
        try {
            if (data.status) {
                this.goToHomePage();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    goToHomePage() {
        this.sessionStorageService.removeLoginStep();
        this.router.navigate([RouterPaths.registered]);
    }

    resetPinInput() {
        (this.userForm.get('digits') as FormArray).clear();
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.userForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    onResend() {
        this.onPhoneSubmit();
        this.resetPinInput();
    }
}
