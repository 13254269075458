import { ElementRef, OnInit, QueryList } from '@angular/core';
import { Validators } from "@angular/forms";
import { PHONE_MASK } from "../../config/country_phone_mask";
import { RouterPaths } from "../../config/router-paths.model";
import * as i0 from "@angular/core";
import * as i1 from "../../modules/auth/_services/authentication.service";
import * as i2 from "@angular/forms";
import * as i3 from "../../service/login.service";
import * as i4 from "@angular/router";
import * as i5 from "../../service/loader.service";
import * as i6 from "../../service/settings.service";
import * as i7 from "../../service/session/sessionStorage.service";
import * as i8 from "../../shared/popup/popup.component";
var PhoneComponent = /** @class */ (function () {
    function PhoneComponent(authenticationService, formBuilder, loginService, router, loaderService, settingsService, activatedRoute, sessionStorageService, popupComponent) {
        this.authenticationService = authenticationService;
        this.formBuilder = formBuilder;
        this.loginService = loginService;
        this.router = router;
        this.loaderService = loaderService;
        this.settingsService = settingsService;
        this.activatedRoute = activatedRoute;
        this.sessionStorageService = sessionStorageService;
        this.popupComponent = popupComponent;
        this.phoneMask = PHONE_MASK.us;
        this.numOfDigits = 4;
        this.isPinShown = false;
    }
    PhoneComponent.prototype.ngOnInit = function () {
        this.authenticationService.logout();
        this.userForm = this.formBuilder.group({
            phone: [null, Validators.required],
            digits: this.formBuilder.array([])
        });
        for (var i = 0; i < this.numOfDigits; i++) {
            this.userForm.get('digits').push(this.formBuilder.control(null, Validators.required));
        }
    };
    PhoneComponent.prototype.onPhoneSubmit = function () {
        var _this = this;
        if (this.userForm.controls.phone.invalid) {
            return;
        }
        var phone = this.userForm.controls.phone.value;
        this.authenticationService.checkPhoneAndSendPin(phone).subscribe(function (data) {
            try {
                if (data.status) {
                    _this.showPinForm();
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    PhoneComponent.prototype.showPinForm = function () {
        var _this = this;
        this.isPinShown = true;
        setTimeout(function () {
            _this.inputs.toArray()[0].nativeElement.focus();
        });
    };
    PhoneComponent.prototype.check = function (index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null);
                this.inputs.toArray()[index - 1].nativeElement.focus();
            }
            else {
                console.log('first field');
            }
        }
    };
    PhoneComponent.prototype.onUserFormSubmit = function () {
        var _this = this;
        if (this.userForm.invalid) {
            return;
        }
        // const promocode = this.sessionStorageService.getPromo();
        // const cashappTag = this.sessionStorageService.getCashAppTag();
        var phone = this.userForm.controls.phone.value;
        this.sessionStorageService.setPhoneNumber(phone);
        var pin = this.userForm.controls.digits.value;
        pin = pin.join('');
        if (pin.length !== 4) {
            this.popupComponent.showPopupError({ text: 'The Code should consist of 4 numerical characters' });
            return;
        }
        if (phone && pin && (pin.length === 4)) {
            this.authenticationService.login(phone, pin).subscribe(function (data) {
                _this.checkLoginSubmit(data);
            }, function (error) {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    PhoneComponent.prototype.checkLoginSubmit = function (data) {
        try {
            if (data.status) {
                this.goToHomePage();
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    PhoneComponent.prototype.goToHomePage = function () {
        this.sessionStorageService.removeLoginStep();
        this.router.navigate([RouterPaths.registered]);
    };
    PhoneComponent.prototype.resetPinInput = function () {
        this.userForm.get('digits').clear();
        for (var i = 0; i < this.numOfDigits; i++) {
            this.userForm.get('digits').push(this.formBuilder.control(null, Validators.required));
        }
    };
    PhoneComponent.prototype.onResend = function () {
        this.onPhoneSubmit();
        this.resetPinInput();
    };
    PhoneComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneComponent_Factory() { return new PhoneComponent(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.FormBuilder), i0.ɵɵinject(i3.LoginService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.LoaderService), i0.ɵɵinject(i6.SettingsService), i0.ɵɵinject(i4.ActivatedRoute), i0.ɵɵinject(i7.SessionStorageService), i0.ɵɵinject(i8.PopupComponent)); }, token: PhoneComponent, providedIn: "root" });
    return PhoneComponent;
}());
export { PhoneComponent };
