import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from "./button/button.component";
import {PopupComponent} from "./popup/popup.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {LoaderComponent} from "./loader/loader.component";
import {MatSelectModule} from "@angular/material";
import {DemoMaterialModule} from "../material.module";

export const APP_DATE_FORMATS = {
    parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
    display: {
        dateInput: {month: 'numeric', year: 'numeric', day: 'numeric'},
        yearLabel: {year: 'numeric'},
        monthLabel: {month: 'short'}
    }
};

@NgModule({
    declarations: [
        ButtonComponent,
        PopupComponent,
        LoaderComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        DemoMaterialModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        MatSelectModule,
    ],
    providers: [
    ],
    exports: [
        ButtonComponent,
        PopupComponent,
        LoaderComponent
    ]
})
export class SharedModule { }
