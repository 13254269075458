import {Injectable} from '@angular/core';
import {LoaderService} from "./loader.service";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {CASH_APP_CONFIG} from "../config/api";

declare var Square: any;

@Injectable({
    providedIn: 'root'
})
export class CashappPayService {
    private config = CASH_APP_CONFIG;

    payments;
    req;
    options = {
        redirectURL: window.location.href,
        referenceId: 'my-distinct-reference-id',
    };
    buttonOptions = {
        shape: 'semiround',
        size: 'medium',
        values: 'dark',
        theme: 'light',
        width: 'full',
    };
    scriptId = 'cashAppScript';

    paymentResult = new BehaviorSubject(null);
    cashAppPay;
    constructor(public loaderService: LoaderService,
                private http: HttpClient) {}

    async createCashAppPayButton(cashappInfo, amount) {
        const script = document.createElement('script');
        script.src = cashappInfo.mode === 'sandbox' ? "https://sandbox.web.squarecdn.com/v1/square.js" : "https://web.squarecdn.com/v1/square.js";
        script.setAttribute('id', this.scriptId);
        script.onload = async () => {
            this.payments = Square.payments(cashappInfo.appId, cashappInfo.locationId);
            this.req = this.payments.paymentRequest({
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    amount: amount,
                    label: 'Total',
                },
            });
            // await this.showCashAppPayButton();
        }
        document.body.appendChild(script);
    }

    async showCashAppPayButton(amount) {
        console.log(this.payments);
        if (this.payments) {
            this.req = this.payments.paymentRequest({
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    amount: String(amount),
                    label: 'Total',
                },
            });
            console.log(String(amount), '2');
            if (!this.cashAppPay) {
              this.cashAppPay = await this.payments.cashAppPay(this.req, this.options);
              this.cashAppPay.addEventListener('ontokenization', (event) => {
                const { tokenResult } = event.detail;
                this.paymentResult.next(tokenResult);
                //this.destroyCashApp();
                // if (tokenResult.status === 'OK') {
                //     cashAppPay.destroy();
                // }
              });
              await this.cashAppPay.attach('#cash-app-pay', this.buttonOptions);
            }
        } else {
            console.log(this.isCashAppPayScripExist(), '2');
            if (this.isCashAppPayScripExist()) {
                this.req = this.payments.paymentRequest({
                    countryCode: 'US',
                    currencyCode: 'USD',
                    total: {
                        amount: amount,
                        label: 'Total',
                    },
                });
                console.log(!this.cashAppPay);
                if (!this.cashAppPay) {
                    this.cashAppPay = await this.payments.cashAppPay(this.req, this.options);
                    this.cashAppPay.addEventListener('ontokenization', (event) => {
                        const { tokenResult } = event.detail;
                        this.paymentResult.next(tokenResult);
                        this.destroyCashApp();
                        // if (tokenResult.status === 'OK') {
                        //     cashAppPay.destroy();
                        // }
                    });
                    await this.cashAppPay.attach('#cash-app-pay', this.buttonOptions);
                }
            }
        }
    }

    isCashAppPayScripExist() {
        return document.querySelector(`#${this.scriptId}`);
    }

    async changeAmount(amount) {
        console.log(amount);
        this.destroyCashApp();
        this.req = this.payments.paymentRequest({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
                amount: amount,
                label: 'Total',
            },
        });
        if (!this.cashAppPay) {
            this.cashAppPay = await this.payments.cashAppPay(this.req, this.options);
            this.cashAppPay.addEventListener('ontokenization', (event) => {
                console.log(event);
                const { tokenResult } = event.detail;
                this.paymentResult.next(tokenResult);
                this.destroyCashApp();
                // if (tokenResult.status === 'OK') {
                //     cashAppPay.destroy();
                // }
            });
            await this.cashAppPay.attach('#cash-app-pay', this.buttonOptions);
        }
    }

    destroyCashApp() {
        if (this.cashAppPay) {
            this.cashAppPay.destroy();
            this.cashAppPay = null;
        }
    }

    onConfirmPayment(amount, token, phone){
        this.loaderService.show();
        const domain = window.location.hostname;
        return this.http.post<any>(this.config.payment, {amount, token,domain, phone});
    }

    getSettings(){
        this.loaderService.show();
        const domain = window.location.hostname;
        return this.http.post<any>(this.config.getSettings, {domain});
    }
}
