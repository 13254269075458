import {Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../loader.service";
import {PARTNERS_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";
import {PartnerResponseModel} from "../../models/partners/partner_response.model";

@Injectable({
    providedIn: 'root'
})
export class PartnersService {
    private config = PARTNERS_CONFIG;
    parentLogoStorageKey = 'parentLogo';
    parentTitleStorageKey = 'parentTitle';

    constructor(private ngZone: NgZone,
                private route: ActivatedRoute,
                private loaderService: LoaderService,
                private http: HttpClient) {}

    getPartner(){
        const domain = window.location.hostname;
        this.loaderService.show();
        return this.http.post<PartnerResponseModel>(this.config.partner, {domain});
    }

    setLogo(logo): void {
        sessionStorage.setItem(this.parentLogoStorageKey, logo);
        document.querySelector('link[rel="icon"]').setAttribute('href', logo);
        document.querySelector('link[rel="apple-touch-icon"][sizes="180x180"]').setAttribute('href', logo);
        document.querySelector('link[rel="apple-touch-icon"][sizes="120x120"]').setAttribute('href', logo);
    }

    setTitle(title): void {
      sessionStorage.setItem(this.parentTitleStorageKey, title);
      document.querySelector('title').innerHTML = title;
    }

    getLogo(): string {
        return sessionStorage.getItem(this.parentLogoStorageKey);
    }

    getTitle(): string {
      return sessionStorage.getItem(this.parentTitleStorageKey);
    }

    removeLogo(): void {
        sessionStorage.removeItem(this.parentLogoStorageKey);
    }

    updatePartnerInfo() {
      if (this.getLogo()) {
        this.setLogo(this.getLogo());
      }
      if (this.getTitle()) {
        this.setTitle(this.getTitle());
      }
    }
}
