import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {UserIdleService} from '../user-idle/user-idle.service';
import {Router} from '@angular/router';
import {AuthConfig} from './auth-config';
import {AUTH_CONFIG} from '../../../config/api';
import {BehaviorSubject, Subject} from 'rxjs';
import {LoaderService} from "../../../service/loader.service";
import {SessionStorageService} from "../../../service/session/sessionStorage.service";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private config = AUTH_CONFIG;

    private storage = sessionStorage;
    roles: string[];
    rolesObservable = new EventEmitter<any>();
    userLoggedIn = new EventEmitter<any>();
    private authStamp;

    constructor(@Inject(AuthConfig) private authConfig: AuthConfig,
                private http: HttpClient,
                private loaderService: LoaderService,
                private sessionStorageService: SessionStorageService
                ) {
    }

    isUserLogged() {
        return !!this.storage.getItem('currentUser');
    }

    checkPhoneAndSendPin(phone: string){
        const domain = window.location.hostname;
        return this.http.post<any>(this.config.checkPhoneAndSendPin, {phone:phone, domain});
    }

    login(phone: string, pin: string) {
        this.loaderService.show();
        const domain = window.location.hostname;
        return this.http.post<any>(this.config.login, {phone, pin, domain})
            .pipe(map((res: any) => {
                if (res.status) {
                    this.setData(res.token);
                    this.userLoggedIn.emit(true);

                    this.sessionStorageService.setAppLink(res.appLink);
                    // this.sessionStorageService.setCashappFrom(res.cashappFrom);
                    this.sessionStorageService.setCashappLink(res.cashappLink);
                }

                return res;
            }));
    }

    setData(result: string){
        this.storage.setItem('currentUser', JSON.stringify({token: result}));
    }

    async logout() {
        this.storage.removeItem('currentUser');
        this.userLoggedIn.emit(false);
        this.sessionStorageService.cleanStorage();
    }

    // setStamp(){
    //     var now = Date.now();
    //
    //     this.authStamp = now;
    //
    //     let $this = this;
    //     setTimeout(function(){
    //         $this.setStamp();
    //     },1000);
    // }
    //
    // checkStamp(){
    //     if (this.storage.getItem('currentUser')) {
    //         var now = Date.now();
    //
    //         if(this.authStamp){
    //             if(now > (this.authStamp+3000000)){
    //                 this.logout();
    //
    //                 this.router.navigate(['/']);
    //
    //                 return;
    //             }else{
    //                 this.refreshTokenNow();
    //             }
    //         }else{
    //             this.refreshTokenNow();
    //         }
    //     }
    // }
    //
    // refreshTokenNow(){
    //     if (this.storage.getItem('currentUser')) {
    //         this.http.post<any>(this.config.refreshToken, {}).subscribe(res => {
    //             if (res.status) {
    //                 this.setData(res.result);
    //             }else{
    //                 this.router.navigate(['/']);
    //             }
    //
    //             return res;
    //         });
    //     }
    //
    //     return false;
    // }
    //
    // refreshToken() {
    //     let $this = this;
    //     setTimeout(function(){
    //         $this.refreshToken();
    //     },60*5*1000);
    //
    //     this.refreshTokenNow();
    // }
    //
    // async checkSession() {
    //
    //     // this.storage = sessionStorage;
    //     // if (this.storage.getItem('currentUser')) {
    //     //
    //     //     this.refreshToken();
    //     // } else {
    //     //     this.router.navigate([this.authConfig.loginRoute],
    //     //         {
    //     //             queryParams: this.authConfig.loginRoute !== location.pathname ?
    //     //                 {
    //     //                     returnUrl:
    //     //                     location.pathname
    //     //                 } : null
    //     //         });
    //     //
    //     // }
    // }

    getCurrentUser(){
        if (sessionStorage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

                return currentUser;
            }catch (e) {

            }
        }

        return false;
    }

    getToken(){
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));

                return currentUser.token;
            }catch (e) {

            }

        }

        return '';
    }

    getRole(){
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));

                return currentUser.role_id;
            }catch (e) {

            }

        }

        return '';
    }

    getPointCountryId(){
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));

                if(currentUser.point_country_id){
                    return currentUser.point_country_id;
                }
            }catch (e) {

            }

        }

        return '';
    }

    getGameVendorShortName(){
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));

                if(currentUser.game_vendor_short_name){
                    return currentUser.game_vendor_short_name;
                }
            }catch (e) {}
        }

        return '';
    }
}
