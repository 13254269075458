import { SETTINGS_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var SettingsService = /** @class */ (function () {
    function SettingsService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = SETTINGS_CONFIG;
    }
    SettingsService.prototype.verifyCashapp = function (promocode, cashappTag) {
        this.loaderService.show();
        var domain = window.location.hostname;
        return this.http.post(this.config.registerCashapp, { promocode: promocode, cashappTag: cashappTag, domain: domain });
    };
    SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
export { SettingsService };
