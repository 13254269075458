import { LOGIN_STEPS } from "../../pages/login/login.config";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SessionStorageService = /** @class */ (function () {
    function SessionStorageService(http) {
        this.http = http;
        this.storage = sessionStorage;
        this.promoCodeKey = 'promoCode';
        this.cashAppTagKey = 'cashAppTag';
        this.loginStep = 'loginStep';
        this.appLinkKey = 'appLink';
        this.cashappFromKey = 'cashappFrom';
        this.cashappLinkKey = 'cashappLink';
        this.phoneNumberKey = 'phoneNumber';
    }
    SessionStorageService.prototype.setPromo = function (val) {
        sessionStorage.setItem(this.promoCodeKey, val);
        this.setLoginStep(LOGIN_STEPS.phone);
    };
    SessionStorageService.prototype.getPromo = function () {
        return sessionStorage.getItem(this.promoCodeKey);
    };
    SessionStorageService.prototype.removePromo = function () {
        sessionStorage.removeItem(this.promoCodeKey);
    };
    SessionStorageService.prototype.setPhoneNumber = function (val) {
        sessionStorage.setItem(this.phoneNumberKey, val);
    };
    SessionStorageService.prototype.getPhoneNumber = function () {
        return sessionStorage.getItem(this.phoneNumberKey);
    };
    SessionStorageService.prototype.removePhoneNumber = function () {
        sessionStorage.removeItem(this.phoneNumberKey);
    };
    SessionStorageService.prototype.setCashAppTag = function (val) {
        sessionStorage.setItem(this.cashAppTagKey, val);
        this.setLoginStep(LOGIN_STEPS.phone);
    };
    SessionStorageService.prototype.getCashAppTag = function () {
        return sessionStorage.getItem(this.cashAppTagKey);
    };
    SessionStorageService.prototype.removeCashAppTag = function () {
        sessionStorage.removeItem(this.cashAppTagKey);
    };
    SessionStorageService.prototype.setLoginStep = function (val) {
        sessionStorage.setItem(this.loginStep, val);
    };
    SessionStorageService.prototype.getLoginStep = function () {
        return sessionStorage.getItem(this.loginStep);
    };
    SessionStorageService.prototype.removeLoginStep = function () {
        sessionStorage.removeItem(this.loginStep);
    };
    SessionStorageService.prototype.setAppLink = function (val) {
        sessionStorage.setItem(this.appLinkKey, val);
    };
    SessionStorageService.prototype.getAppLink = function () {
        return sessionStorage.getItem(this.appLinkKey);
    };
    SessionStorageService.prototype.removeAppLink = function () {
        sessionStorage.removeItem(this.appLinkKey);
    };
    SessionStorageService.prototype.setCashappFrom = function (val) {
        sessionStorage.setItem(this.cashappFromKey, val);
    };
    SessionStorageService.prototype.getCashappFrom = function () {
        return sessionStorage.getItem(this.cashappFromKey);
    };
    SessionStorageService.prototype.removeCashappFrom = function () {
        sessionStorage.removeItem(this.cashappFromKey);
    };
    SessionStorageService.prototype.setCashappLink = function (val) {
        sessionStorage.setItem(this.cashappLinkKey, val);
    };
    SessionStorageService.prototype.getCashappLink = function () {
        return sessionStorage.getItem(this.cashappLinkKey);
    };
    SessionStorageService.prototype.removeCashappLink = function () {
        sessionStorage.removeItem(this.cashappLinkKey);
    };
    SessionStorageService.prototype.cleanStorage = function () {
        this.removePromo();
        this.removeCashAppTag();
        this.removeLoginStep();
        this.removeAppLink();
        this.removeCashappFrom();
        this.removeCashappLink();
        this.removePhoneNumber();
    };
    SessionStorageService.prototype.getUserToken = function () {
        return JSON.parse(this.storage.getItem('currentUser')).token;
    };
    SessionStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionStorageService_Factory() { return new SessionStorageService(i0.ɵɵinject(i1.HttpClient)); }, token: SessionStorageService, providedIn: "root" });
    return SessionStorageService;
}());
export { SessionStorageService };
