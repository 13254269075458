import * as tslib_1 from "tslib";
import { BehaviorSubject } from "rxjs";
import { CASH_APP_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "./loader.service";
import * as i2 from "@angular/common/http";
var CashappPayService = /** @class */ (function () {
    function CashappPayService(loaderService, http) {
        this.loaderService = loaderService;
        this.http = http;
        this.config = CASH_APP_CONFIG;
        this.options = {
            redirectURL: window.location.href,
            referenceId: 'my-distinct-reference-id',
        };
        this.buttonOptions = {
            shape: 'semiround',
            size: 'medium',
            values: 'dark',
            theme: 'light',
            width: 'full',
        };
        this.scriptId = 'cashAppScript';
        this.paymentResult = new BehaviorSubject(null);
    }
    CashappPayService.prototype.createCashAppPayButton = function (cashappInfo, amount) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var script;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                script = document.createElement('script');
                script.src = cashappInfo.mode === 'sandbox' ? "https://sandbox.web.squarecdn.com/v1/square.js" : "https://web.squarecdn.com/v1/square.js";
                script.setAttribute('id', this.scriptId);
                script.onload = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.payments = Square.payments(cashappInfo.appId, cashappInfo.locationId);
                        this.req = this.payments.paymentRequest({
                            countryCode: 'US',
                            currencyCode: 'USD',
                            total: {
                                amount: amount,
                                label: 'Total',
                            },
                        });
                        return [2 /*return*/];
                    });
                }); };
                document.body.appendChild(script);
                return [2 /*return*/];
            });
        });
    };
    CashappPayService.prototype.showCashAppPayButton = function (amount) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        console.log(this.payments);
                        if (!this.payments) return [3 /*break*/, 4];
                        this.req = this.payments.paymentRequest({
                            countryCode: 'US',
                            currencyCode: 'USD',
                            total: {
                                amount: String(amount),
                                label: 'Total',
                            },
                        });
                        console.log(String(amount), '2');
                        if (!!this.cashAppPay) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.payments.cashAppPay(this.req, this.options)];
                    case 1:
                        _a.cashAppPay = _c.sent();
                        this.cashAppPay.addEventListener('ontokenization', function (event) {
                            var tokenResult = event.detail.tokenResult;
                            _this.paymentResult.next(tokenResult);
                            //this.destroyCashApp();
                            // if (tokenResult.status === 'OK') {
                            //     cashAppPay.destroy();
                            // }
                        });
                        return [4 /*yield*/, this.cashAppPay.attach('#cash-app-pay', this.buttonOptions)];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3: return [3 /*break*/, 7];
                    case 4:
                        console.log(this.isCashAppPayScripExist(), '2');
                        if (!this.isCashAppPayScripExist()) return [3 /*break*/, 7];
                        this.req = this.payments.paymentRequest({
                            countryCode: 'US',
                            currencyCode: 'USD',
                            total: {
                                amount: amount,
                                label: 'Total',
                            },
                        });
                        console.log(!this.cashAppPay);
                        if (!!this.cashAppPay) return [3 /*break*/, 7];
                        _b = this;
                        return [4 /*yield*/, this.payments.cashAppPay(this.req, this.options)];
                    case 5:
                        _b.cashAppPay = _c.sent();
                        this.cashAppPay.addEventListener('ontokenization', function (event) {
                            var tokenResult = event.detail.tokenResult;
                            _this.paymentResult.next(tokenResult);
                            _this.destroyCashApp();
                            // if (tokenResult.status === 'OK') {
                            //     cashAppPay.destroy();
                            // }
                        });
                        return [4 /*yield*/, this.cashAppPay.attach('#cash-app-pay', this.buttonOptions)];
                    case 6:
                        _c.sent();
                        _c.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    CashappPayService.prototype.isCashAppPayScripExist = function () {
        return document.querySelector("#" + this.scriptId);
    };
    CashappPayService.prototype.changeAmount = function (amount) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log(amount);
                        this.destroyCashApp();
                        this.req = this.payments.paymentRequest({
                            countryCode: 'US',
                            currencyCode: 'USD',
                            total: {
                                amount: amount,
                                label: 'Total',
                            },
                        });
                        if (!!this.cashAppPay) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.payments.cashAppPay(this.req, this.options)];
                    case 1:
                        _a.cashAppPay = _b.sent();
                        this.cashAppPay.addEventListener('ontokenization', function (event) {
                            console.log(event);
                            var tokenResult = event.detail.tokenResult;
                            _this.paymentResult.next(tokenResult);
                            _this.destroyCashApp();
                            // if (tokenResult.status === 'OK') {
                            //     cashAppPay.destroy();
                            // }
                        });
                        return [4 /*yield*/, this.cashAppPay.attach('#cash-app-pay', this.buttonOptions)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CashappPayService.prototype.destroyCashApp = function () {
        if (this.cashAppPay) {
            this.cashAppPay.destroy();
            this.cashAppPay = null;
        }
    };
    CashappPayService.prototype.onConfirmPayment = function (amount, token, phone) {
        this.loaderService.show();
        var domain = window.location.hostname;
        return this.http.post(this.config.payment, { amount: amount, token: token, domain: domain, phone: phone });
    };
    CashappPayService.prototype.getSettings = function () {
        this.loaderService.show();
        var domain = window.location.hostname;
        return this.http.post(this.config.getSettings, { domain: domain });
    };
    CashappPayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CashappPayService_Factory() { return new CashappPayService(i0.ɵɵinject(i1.LoaderService), i0.ɵɵinject(i2.HttpClient)); }, token: CashappPayService, providedIn: "root" });
    return CashappPayService;
}());
export { CashappPayService };
