import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {LoaderService} from "./loader.service";
import {SETTINGS_CONFIG} from "../config/api";


@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private config = SETTINGS_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
    }

    verifyCashapp(promocode, cashappTag) {
        this.loaderService.show();
        const domain = window.location.hostname;
        return this.http.post<any>(this.config.registerCashapp, {promocode, cashappTag, domain});
    }
}
