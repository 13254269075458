<div class="login">
    <div class="login__inner">
        <div class="login__header">
            <img class="logo" [src]="partnerLogo" alt="">
        </div>

<!--        <button class="button button&#45;&#45;back"-->
<!--                *ngIf="isCashappShown || (!isCashappShown && !isPromoShown)"-->
<!--                (click)="goBack()">-->
<!--            Back-->
<!--        </button>-->

        <router-outlet></router-outlet>

        <div class="login__footer">
            <p class="text t-grey">Services are to be used for amusement purposes only. Customer acknowledges that the Services may be subject to U.S., state, country and municipal laws and regulations. Accordingly, Customer may not use of the Service except in full compliance with all applicable laws and regulations. Customer is responsible for understanding and using the Services in a manner that does not violate federal, state or local law</p>
        </div>
    </div>
</div>
