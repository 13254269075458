import { NgZone } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { PARTNERS_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../loader.service";
import * as i3 from "@angular/common/http";
var PartnersService = /** @class */ (function () {
    function PartnersService(ngZone, route, loaderService, http) {
        this.ngZone = ngZone;
        this.route = route;
        this.loaderService = loaderService;
        this.http = http;
        this.config = PARTNERS_CONFIG;
        this.parentLogoStorageKey = 'parentLogo';
        this.parentTitleStorageKey = 'parentTitle';
    }
    PartnersService.prototype.getPartner = function () {
        var domain = window.location.hostname;
        this.loaderService.show();
        return this.http.post(this.config.partner, { domain: domain });
    };
    PartnersService.prototype.setLogo = function (logo) {
        sessionStorage.setItem(this.parentLogoStorageKey, logo);
        document.querySelector('link[rel="icon"]').setAttribute('href', logo);
        document.querySelector('link[rel="apple-touch-icon"][sizes="180x180"]').setAttribute('href', logo);
        document.querySelector('link[rel="apple-touch-icon"][sizes="120x120"]').setAttribute('href', logo);
    };
    PartnersService.prototype.setTitle = function (title) {
        sessionStorage.setItem(this.parentTitleStorageKey, title);
        document.querySelector('title').innerHTML = title;
    };
    PartnersService.prototype.getLogo = function () {
        return sessionStorage.getItem(this.parentLogoStorageKey);
    };
    PartnersService.prototype.getTitle = function () {
        return sessionStorage.getItem(this.parentTitleStorageKey);
    };
    PartnersService.prototype.removeLogo = function () {
        sessionStorage.removeItem(this.parentLogoStorageKey);
    };
    PartnersService.prototype.updatePartnerInfo = function () {
        if (this.getLogo()) {
            this.setLogo(this.getLogo());
        }
        if (this.getTitle()) {
            this.setTitle(this.getTitle());
        }
    };
    PartnersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PartnersService_Factory() { return new PartnersService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.LoaderService), i0.ɵɵinject(i3.HttpClient)); }, token: PartnersService, providedIn: "root" });
    return PartnersService;
}());
export { PartnersService };
