import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthConfig } from './auth-config';
import { AUTH_CONFIG } from '../../../config/api';
import * as i0 from "@angular/core";
import * as i1 from "./auth-config";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../service/loader.service";
import * as i4 from "../../../service/session/sessionStorage.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(authConfig, http, loaderService, sessionStorageService) {
        this.authConfig = authConfig;
        this.http = http;
        this.loaderService = loaderService;
        this.sessionStorageService = sessionStorageService;
        this.config = AUTH_CONFIG;
        this.storage = sessionStorage;
        this.rolesObservable = new EventEmitter();
        this.userLoggedIn = new EventEmitter();
    }
    AuthenticationService.prototype.isUserLogged = function () {
        return !!this.storage.getItem('currentUser');
    };
    AuthenticationService.prototype.checkPhoneAndSendPin = function (phone) {
        var domain = window.location.hostname;
        return this.http.post(this.config.checkPhoneAndSendPin, { phone: phone, domain: domain });
    };
    AuthenticationService.prototype.login = function (phone, pin) {
        var _this = this;
        this.loaderService.show();
        var domain = window.location.hostname;
        return this.http.post(this.config.login, { phone: phone, pin: pin, domain: domain })
            .pipe(map(function (res) {
            if (res.status) {
                _this.setData(res.token);
                _this.userLoggedIn.emit(true);
                _this.sessionStorageService.setAppLink(res.appLink);
                // this.sessionStorageService.setCashappFrom(res.cashappFrom);
                _this.sessionStorageService.setCashappLink(res.cashappLink);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.setData = function (result) {
        this.storage.setItem('currentUser', JSON.stringify({ token: result }));
    };
    AuthenticationService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.storage.removeItem('currentUser');
                this.userLoggedIn.emit(false);
                this.sessionStorageService.cleanStorage();
                return [2 /*return*/];
            });
        });
    };
    // setStamp(){
    //     var now = Date.now();
    //
    //     this.authStamp = now;
    //
    //     let $this = this;
    //     setTimeout(function(){
    //         $this.setStamp();
    //     },1000);
    // }
    //
    // checkStamp(){
    //     if (this.storage.getItem('currentUser')) {
    //         var now = Date.now();
    //
    //         if(this.authStamp){
    //             if(now > (this.authStamp+3000000)){
    //                 this.logout();
    //
    //                 this.router.navigate(['/']);
    //
    //                 return;
    //             }else{
    //                 this.refreshTokenNow();
    //             }
    //         }else{
    //             this.refreshTokenNow();
    //         }
    //     }
    // }
    //
    // refreshTokenNow(){
    //     if (this.storage.getItem('currentUser')) {
    //         this.http.post<any>(this.config.refreshToken, {}).subscribe(res => {
    //             if (res.status) {
    //                 this.setData(res.result);
    //             }else{
    //                 this.router.navigate(['/']);
    //             }
    //
    //             return res;
    //         });
    //     }
    //
    //     return false;
    // }
    //
    // refreshToken() {
    //     let $this = this;
    //     setTimeout(function(){
    //         $this.refreshToken();
    //     },60*5*1000);
    //
    //     this.refreshTokenNow();
    // }
    //
    // async checkSession() {
    //
    //     // this.storage = sessionStorage;
    //     // if (this.storage.getItem('currentUser')) {
    //     //
    //     //     this.refreshToken();
    //     // } else {
    //     //     this.router.navigate([this.authConfig.loginRoute],
    //     //         {
    //     //             queryParams: this.authConfig.loginRoute !== location.pathname ?
    //     //                 {
    //     //                     returnUrl:
    //     //                     location.pathname
    //     //                 } : null
    //     //         });
    //     //
    //     // }
    // }
    AuthenticationService.prototype.getCurrentUser = function () {
        if (sessionStorage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                return currentUser;
            }
            catch (e) {
            }
        }
        return false;
    };
    AuthenticationService.prototype.getToken = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.token;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getRole = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.role_id;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getPointCountryId = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                if (currentUser.point_country_id) {
                    return currentUser.point_country_id;
                }
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getGameVendorShortName = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                if (currentUser.game_vendor_short_name) {
                    return currentUser.game_vendor_short_name;
                }
            }
            catch (e) { }
        }
        return '';
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.LoaderService), i0.ɵɵinject(i4.SessionStorageService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
