import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
var RegisteredComponent = /** @class */ (function () {
    function RegisteredComponent(sessionStorageService, popupComponent, loaderService, formBuilder, cashappPayService) {
        this.sessionStorageService = sessionStorageService;
        this.popupComponent = popupComponent;
        this.loaderService = loaderService;
        this.formBuilder = formBuilder;
        this.cashappPayService = cashappPayService;
        this.cashAppLink = '';
        this.appLink = '';
        this.showCashAppForm = false;
    }
    RegisteredComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.cashAppForm = this.formBuilder.group({
                    amount: [null, Validators.required],
                });
                this.getCashAppSettings();
                this.cashappPayService.destroyCashApp();
                this.cashAppLink = this.sessionStorageService.getCashappLink();
                this.appLink = this.sessionStorageService.getAppLink();
                this.cashappPayService.paymentResult.subscribe(function (tokenResult) {
                    //console.log(tokenResult);
                    if (tokenResult) {
                        try {
                            // console.log(tokenResult, '1');
                            if (tokenResult.status === 'OK') {
                                // console.log(`Payment token is ${tokenResult.token}`);
                                var body = {
                                    token: tokenResult.token
                                };
                                // $.post("https://api.dev.eboxenterprises.com/test/square.php?tt=1", body, (data, status) => {
                                //   console.log(data);
                                // });
                                _this.onDepositConfirm(tokenResult.token);
                            }
                            else {
                                // console.log(tokenResult, '2');
                                _this.popupComponent.showPopupError({ text: 'Payment is canceled' });
                                _this.cashAppForm.controls.amount.reset();
                                _this.closeCashAppForm();
                            }
                            _this.cashappPayService.paymentResult.next(null);
                        }
                        catch (e) {
                            console.log(e);
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    RegisteredComponent.prototype.getCashAppSettings = function () {
        // const data = {
        //   status: true,
        //   cashapp: {
        //     "appId": "sandbox-sq0idb-vroAoylGDSnNU--Au1YG2w",
        //     "locationId": "LFHR510D04M1J",
        //     "mode": "sandbox"
        //   },
        //   message: 'OK'
        // };
        var _this = this;
        this.cashappPayService.getSettings().subscribe(function (data) {
            _this.setCashAppSettings(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    RegisteredComponent.prototype.setCashAppSettings = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var amount, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!data.status) return [3 /*break*/, 3];
                        if (!!this.cashappPayService.isCashAppPayScripExist()) return [3 /*break*/, 2];
                        amount = '1.00';
                        return [4 /*yield*/, this.cashappPayService.createCashAppPayButton(data['cashapp'], amount)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        this.popupComponent.showPopupError({ text: data.message });
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        this.loaderService.hideAndShowTryAgain();
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    RegisteredComponent.prototype.getAppLink = function () {
        return this.appLink + '/senetpro?token=' + this.sessionStorageService.getUserToken();
    };
    RegisteredComponent.prototype.getUserToken = function () {
        return this.sessionStorageService.getUserToken();
    };
    RegisteredComponent.prototype.onDepositConfirm = function (token) {
        var _this = this;
        var amount = this.cashAppForm.controls.amount.value;
        var phone = this.sessionStorageService.getPhoneNumber();
        this.cashappPayService.onConfirmPayment(amount, token, phone).subscribe(function (data) {
            // const data = {
            //     status: true,
            //     message: `All is OK buyWithCashApp, ${amount}, ${token}`
            // };
            _this.setDepositConfirmResult(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    RegisteredComponent.prototype.setDepositConfirmResult = function (data) {
        try {
            if (data.status) {
                // this.popupComponent.showPopupErrorReturnEvent({text: data.message}, () => {
                //     window.location.href = this.getAppLink();
                // });
                this.closeCashAppForm();
                this.popupComponent.showPopupSuccess({ text: data.message });
                window.location.href = this.getAppLink();
            }
            else {
                var amount = +this.cashAppForm.controls.amount.value;
                this.cashappPayService.changeAmount(amount.toFixed(2));
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            var amount = +this.cashAppForm.controls.amount.value;
            this.cashappPayService.changeAmount(amount.toFixed(2));
            this.loaderService.hideAndShowTryAgain();
        }
    };
    RegisteredComponent.prototype.openCashAppForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showCashAppForm = true;
                        document.body.querySelector('.popup-cash-app').classList.add('is-active');
                        document.body.classList.add('is-blurred');
                        return [4 /*yield*/, this.cashappPayService.showCashAppPayButton('1.00')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RegisteredComponent.prototype.closeCashAppForm = function () {
        this.showCashAppForm = false;
        document.body.querySelector('.popup-cash-app').classList.remove('is-active');
        document.body.classList.remove('is-blurred');
        this.cashAppForm.reset();
    };
    RegisteredComponent.prototype.openCashAppFormConfirm = function () {
        var _this = this;
        this.cashAppForm.controls.amount.disable();
        setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cashappPayService.showCashAppPayButton('0.00')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    RegisteredComponent.prototype.onAmountChanged = function (e) {
        var amount = +e.target.value.replace(/\s/g, '');
        if (!amount) {
            amount = 1;
        }
        this.cashappPayService.changeAmount(amount.toFixed(2));
    };
    return RegisteredComponent;
}());
export { RegisteredComponent };
