import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LOGIN_STEPS} from "../../pages/login/login.config";


@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    private storage = sessionStorage;
    private promoCodeKey = 'promoCode';
    private cashAppTagKey = 'cashAppTag';
    private loginStep = 'loginStep';

    private appLinkKey = 'appLink';
    private cashappFromKey = 'cashappFrom';
    private cashappLinkKey = 'cashappLink';

    private phoneNumberKey = 'phoneNumber';


    constructor(private http: HttpClient) {

    }

    setPromo(val) {
        sessionStorage.setItem(this.promoCodeKey, val);
        this.setLoginStep(LOGIN_STEPS.phone);
    }

    getPromo() {
        return sessionStorage.getItem(this.promoCodeKey);
    }

    removePromo(): void {
        sessionStorage.removeItem(this.promoCodeKey);
    }

    setPhoneNumber(val) {
        sessionStorage.setItem(this.phoneNumberKey, val);
    }

    getPhoneNumber() {
        return sessionStorage.getItem(this.phoneNumberKey);
    }

    removePhoneNumber(): void {
        sessionStorage.removeItem(this.phoneNumberKey);
    }


    setCashAppTag(val) {
        sessionStorage.setItem(this.cashAppTagKey, val);
        this.setLoginStep(LOGIN_STEPS.phone);
    }

    getCashAppTag() {
        return sessionStorage.getItem(this.cashAppTagKey);
    }

    removeCashAppTag(): void {
        sessionStorage.removeItem(this.cashAppTagKey);
    }


    setLoginStep(val) {
        sessionStorage.setItem(this.loginStep, val);
    }

    getLoginStep() {
        return sessionStorage.getItem(this.loginStep);
    }

    removeLoginStep(): void {
        sessionStorage.removeItem(this.loginStep);
    }


    setAppLink(val) {
        sessionStorage.setItem(this.appLinkKey, val);
    }

    getAppLink() {
        return sessionStorage.getItem(this.appLinkKey);
    }

    removeAppLink(): void {
        sessionStorage.removeItem(this.appLinkKey);
    }


    setCashappFrom(val) {
        sessionStorage.setItem(this.cashappFromKey, val);
    }

    getCashappFrom() {
        return sessionStorage.getItem(this.cashappFromKey);
    }

    removeCashappFrom(): void {
        sessionStorage.removeItem(this.cashappFromKey);
    }

    setCashappLink(val) {
        sessionStorage.setItem(this.cashappLinkKey, val);
    }

    getCashappLink() {
        return sessionStorage.getItem(this.cashappLinkKey);
    }

    removeCashappLink(): void {
        sessionStorage.removeItem(this.cashappLinkKey);
    }

    cleanStorage() {
        this.removePromo();
        this.removeCashAppTag();
        this.removeLoginStep();
        this.removeAppLink();
        this.removeCashappFrom();
        this.removeCashappLink();
        this.removePhoneNumber();
    }

    getUserToken(){
        return JSON.parse(this.storage.getItem('currentUser')).token;
    }
}
