/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./login.component";
import * as i4 from "../../shared/popup/popup.component";
import * as i5 from "../../service/loader.service";
import * as i6 from "../../service/partners/partners.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: { "animation": [{ type: 7, name: "slideToRight", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "login__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "login__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "logo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "login__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "text t-grey"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Services are to be used for amusement purposes only. Customer acknowledges that the Services may be subject to U.S., state, country and municipal laws and regulations. Accordingly, Customer may not use of the Service except in full compliance with all applicable laws and regulations. Customer is responsible for understanding and using the Services in a manner that does not violate federal, state or local law"]))], function (_ck, _v) { _ck(_v, 5, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.partnerLogo; _ck(_v, 3, 0, currVal_0); }); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], [[40, "@slideToRight", 0]], null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoginComponent, [i4.PopupComponent, i5.LoaderService, i6.PartnersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i3.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
